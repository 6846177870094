import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='footer-section'>
      {`©${currentYear}  France Messi | `}
      <a href='https://line.me/ti/p/M12YVS2Tf8'>Line</a> |{' '}
     
    </div>
  );
};

export default Footer;
