import { formatDistanceToNow } from 'date-fns';

const intro = {
  exec: ({ structure, history, cwd }, command) => {
    const bDate = new Date('1998-08-02');
    const cDate = formatDistanceToNow(bDate);

    return {
      structure,
      cwd,
      history: history
        .concat({ value: ">_ Hi is me Nantawat Chareonphon, France or France Messi" })
        .concat({ value: ">_ 16 personalities (ENTP)" })

        .concat({ value: `>_ August 2, 1998, Bangkok, THAILAND, Age are ${cDate}` })
        .concat({ value: '>_ Grew up in Nakhon Ratchasima.' })
        .concat({
          value: '>_ Now, Node JS Developer, Full Stack, Database Design,Testing, Mobile app.',
        })
        .concat({ value: '>' })

        .concat({
          value: '>_ Dedicated and highly motivated Full-Stack Developer with a passion for creating innovative and efficient web applications. Armed with a solid foundation in both front-end and back-end technologies, I aim to leverage my skills and experience to contribute to a dynamic development team. Seeking a challenging position where I can apply my expertise in designing and implementing scalable solutions, while continuously expanding my knowledge in emerging technologies.',
        })
        .concat({ value: '>' })

        .concat({ value: '>_ Future, I wanna be startup founder.' })
        .concat({ value: '>' }),
    };
  },
};

const edu = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history
        .concat({ value: '>_ Bachelor of Science (B.S.in Computer Science) 2017-2021' })
        .concat({
          value: '>_ Thammasat University',
        })
        .concat({ value: '>_ Grade point average : 3.01' })
        .concat({ value: '>_' })
        .concat({ value: '>_ High School' })
        .concat({ value: '>_ RajsimaWittayalai School (Nakhon Ratchasima)' })
        .concat({ value: '>' }),
    };
  },
};

const lang = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history
        .concat({ value: ">_ THAI : 5/5 => I'm an excellent communicator." })
        .concat({
          value:
            '>_ ENGLISH : 3/5 => Can survive in foreign country. Read and write are pretty good.',
        })
       
        .concat({ value: '>' }),
    };
  },
};

const stskill = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history
        .concat({ value: '>_ Clean Code' })
        .concat({ value: ">_ There's nothing that can't be done." })
        .concat({ value: '>_ I communicate well' })
        .concat({ value: '>_ Have determination to know' })
        .concat({ value: '>_ Learn fast' })


        .concat({ value: '>' }),
    };
  },
};

const pskill = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history
        .concat({
          value: '>_ Now, Expertise about Flutter, Android, Node JS, Full Stack Developer',
        })
        .concat({ value: '>_ ' })
        .concat({
          value:
            '>_ Backend  => Building from zero, maintenance, researching new tech & libs',
        })
        .concat({
          value:
            '>_ Frontend => Building from zero, maintenance, researching new ux/ui trend & libs, Bootstrap',
        })
        .concat({
          value:
            '>_ Windows Server => Understanding about system operation & know about how to solve problems',
        })
        .concat({ value: '>_ Linux Server => Can use basic Linux command & researching' })
        .concat({ value: '>_ ' })
        .concat({ value: '>_ PROGRAMMING LANGUAGE' })
        .concat({ value: '>_ Advanced => Node JS, Java, C++' })
        .concat({ value: '>_ Intermediate => Dart' })
        .concat({ value: '>_ Beginner => Python' })
        .concat({ value: '>_ ' })
        .concat({ value: '>_ WEB STACK & TOOLS ' })
        .concat({
          value:
            '>_ React,Angular, RESTApi, Node.js, MySql, MongoDB, Firebase, Git, Flutter, Android, PDFMake, CI/CD',
        })
        .concat({ value: '>_ ' })
        .concat({ value: '>_ INTERESTED NOW' })
        .concat({
          value: '>_ UX/UI, Cloud Technology, Blockchain, Podcasts, Invesment.',
        })
        .concat({ value: '>' }),
    };
  },
};

const sskill = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history   
        .concat({ value: '>_ Sports => Badminton, Football, Running' })
        .concat({ value: '>_ Games => Dota2 (for waching)' })
        .concat({ value: '>_ Treader => Stock, Crypto' })
        .concat({ value: '>' }),
    };
  },
};

const awards = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history.concat({ value: '>_ ???' }).concat({ value: '>' }),
    };
  },
};

const exp = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history
        .concat({ value: '>_ 2021 - Now : Software Developer, infilight co. ltd' })
        .concat({ value: '>' })

        .concat({ value: '>_ Used a range of languages, full-stack development mobile technology connect database SQL MongoDB create API and design database structure I am keen to develop as a collaborator in software development ' })
        .concat({ value: '>' })

        .concat({
          value: '>_ 2018 - 2021 : Software Developer Part time, infilight co. ltd.',
        })
        .concat({ value: '>' })

        .concat({
          value: '>_     Used a range of languages, operating systems and development tools as well as experiencing the system development life cycle. Specialising in Web application and mobile technology, I am keen to develop as a graduate trainee in software development',
        })
        .concat({ value: '>' })

        .concat({ value: '>' }),
    };
  },
};

const contact = {
  exec: ({ structure, history, cwd }, command) => {
    return {
      structure,
      cwd,
      history: history
        .concat({ value: '>_ Email : France Messi' })
        .concat({ value: '>_ Twitter : @francexavimessi' })
        .concat({ value: '>_ Instagram : francemessi' })
        .concat({ value: '>_ Line : francexavi' })
        .concat({ value: '>' }),
    };
  },
};

export { intro, edu, lang, stskill, pskill, sskill, awards, exp, contact };
